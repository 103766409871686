<template>
  <div class="wallent">
    <van-nav-bar
        title=""
        left-arrow
        right-text="联惠金明细"
        @click-left="onClickLeft"
        @click-right="onClickRight"
    />
    <div class="wallent_content" :style="'margin-top: ' + (screenWidth * 0.1) + 'px;'">
      <van-icon name="gold-coin" color="#FFC300" size="60" />
      <van-cell :border="false" title="我的联惠金" title-class="content_text" />
      <van-cell :border="false" title-class="content_money">
        <template #title>
          <span>&yen;</span>{{userinfo.credit5}}
        </template>
      </van-cell>
      <van-cell-group :border="false" style="margin-top: 20px;">
        <van-cell :border="false">
          <template #title>
            <van-button type="primary" size="small" @click="wxchongzhi">微信充值</van-button>
          </template>
        </van-cell>
      </van-cell-group>
      <div class="chongzhi_title">
        <van-cell :border="false" is-link @click="rechargehistory">
          <template #title>
            充值记录
          </template>
        </van-cell>
      </div>
    </div>
  </div>
</template>
<script>
import { ImagePreview } from 'vant';
export default {
  name: "Wallent",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      userinfo: {},
    }
  },
  mounted() {
    this.getinfo();
  },
  methods: {
    // 获取用户余额
    getinfo() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(_that.$store.state.domain + "team/user/get_info")
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.userinfo = res.data.data;
          }else {
            _that.$toast(res.data.msg ? res.data.msg : "获取数据失败");
          }
        })
        .catch(err => {
          console.log(err);
        })
    },

    // 跳转到余额列表页
    onClickRight() {
      this.$router.push({
        name: "Accountlog",
        query: {
          type: 'credit5',
          t: new Date().getTime()
        }
      })
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back();
    },
    // 微信充值
    wxchongzhi() {
      this.$router.push({
        name: "Recharge",
        query: {
          t: new Date().getTime()
        }
      })
    },
    // 充值记录
    rechargehistory() {
      this.$router.push({
        name: "RechargeHistory",
        query: {
          t: new Date().getTime()
        }
      })
    },
  }
}
</script>
<style lang="less">
.wallent {
  overflow: hidden;
  width: 100%;
  min-height: 100%;
  background-color: #fff;
  .van-nav-bar {
    .van-icon {
      color: #333;
    }
    .van-nav-bar__text {
      color: #333;
    }
  }
  .wallent_content{
    padding-bottom: 60px;
    overflow: hidden;
    .van-cell {
      .van-cell__title {
        flex: 1;
        text-align: center;
      }
      .chognzhi_tishi {
        color: #f00;
        font-size: 14px;
        font-weight: bold;
      }
      .content_text {
        padding-top: 20px;
        color: #333;
        font-size: 16px;
      }
      .content_money {
        color: #333;
        font-size: 40px;
        span {
          font-size: 20px;
          margin-right: 5px;
        }
      }
    }
    .van-cell-group {
      .van-cell {
        .van-button {
          width: 200px;
          height: 46px;
        }
      }
    }
    .chongzhi_title {
      width: 110px;
      margin: 10px auto 0;
      .van-cell {
        color: #1989FA;
        .van-cell__right-icon {
          color: #1989FA;
        }
      }
    }
    .shili {
      padding: 0 20px;
      margin-top: 10px;
      .van-cell__title,
      .van-cell__value {
        flex: 1;
        text-align: center;
        color: #333;
      }
      .shili_title {
        font-size: 14px;
        padding: 0 16px;
        // text-align: left;
      }
      .shili_item {
        color: #333;
        text-align: left;
      }
    }
  }
}
</style>